export const getBadgeColorClass = (color, withBorder = true) => {
	if (color == `blue`) return `text-blue-700 bg-blue-100 ${withBorder ? 'border border-blue-300' : 'border-0'}`;
	if (color == `green`) return `text-green-700 bg-green-100 ${withBorder ? 'border border-green-300' : 'border-0'}`;
	if (color == `red`) return `text-red-700 bg-red-100 ${withBorder ? 'border border-red-300' : 'border-0'}`;
	if (color == `yellow`) return `text-yellow-700 bg-yellow-100 ${withBorder ? 'border border-yellow-300' : 'border-0'}`;
	if (color == `orange`) return `text-orange-700 bg-orange-100 ${withBorder ? 'border border-orange-300' : 'border-0'}`;
	if (color == `purple`) return `text-purple-700 bg-purple-100 ${withBorder ? 'border border-purple-300' : 'border-0'}`;
	if (color == `indigo`) return `text-indigo-700 bg-indigo-100 ${withBorder ? 'border border-indigo-300' : 'border-0'}`;
	if (color == `pink`) return `text-pink-800 bg-pink-100 ${withBorder ? 'border border-pink-300' : 'border-0'}`;
	if (color == `gray`) return `text-gray-700 bg-gray-100 ${withBorder ? 'border border-gray-300' : 'border-0'}`;
	if (color == `medium-gray`) return `text-gray-700 bg-gray-200 ${withBorder ? 'border border-gray-400' : 'border-0'}`;
	if (color == `white`) return `text-black bg-white ${withBorder ? 'border border-white-300' : 'border-0'}`;
	if (color == `black`) return `text-white bg-black ${withBorder ? 'border border-black-300' : 'border-0'}`;
	if (color == 'none') return ``;
};
