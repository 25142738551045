<script>
	import { getBadgeColorClass } from '$lib/js/ui/badges';
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let withBorder = true;
	export let color = 'blue';
	export let sizeClass = 'text-sm';
	export let paddingClasses = 'px-2.5 py-1';
	export let fullWidth = false;
	export let disabled = false;

	const sharedClasses = `font-medium ${paddingClasses} rounded-lg ${fullWidth ? 'w-full' : 'w-auto'} grid content-center`;

	$: colorClasses = getBadgeColorClass(color, withBorder);
</script>

{#key colorClasses}
	<div
		class="{colorClasses} {sizeClass} {sharedClasses} {$$props.class}"
		style={$$props.style}
		on:click={() => !disabled && dispatch('click')}
	>
		<slot />
	</div>
{/key}
